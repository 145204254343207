// import BG from "assets/images/bg/header-background.png";

// import { Parallax } from "react-parallax";

  
  const PageHeader = (title) => {
    return (
      <div className="">
        <div className="relative isolate overflow-hidden py-24 sm:py-32 bg-main-bg bg-center bg-cover bg-fixed bg-[#002147]">
          {/* <img
            src="assests/images/bg/header-background-2.jpg"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-100"
          /> */}

          <div className="mx-auto max-w-[1140px] px-6 lg:px-8 flex justify-center items-center">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center font-[Poppins] uppercase">
                {title.heading}
              </h2>
              {/* <p className="mt-6 text-lg leading-8 text-gray-300">{title.des}</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default PageHeader;